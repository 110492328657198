import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/404";

const routes = [
  {
    path: "/",
    name: "Index",
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/Index.vue")
  },
  {
    path: "/vacancies",
    name: "Vacancies",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "vacancies" */ "../views/Vacancies.vue")
  },
  {
    path: "/vacancies/:slug/",
    name: "Vacancy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "vacancy" */ "../views/Vacancy.vue")
  },
  { path: "/not-found", name: "NotFound", component: NotFound }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  scrollBehavior(to, from) {
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else if (to.name === "Index" && from.hash) {
      return { top: 0, behavior: "smooth" };
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach(to => {
  if (!router.hasRoute(to.name)) {
    return router.replace({ name: "NotFound" });
  }
});
export default router;
