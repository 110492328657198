<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-wrap">
        <div class="footer-col footer-details" id="contacts">
          <h3 class="h3-text underline">
            Наши контакты
          </h3>
          <ul class="footer-list">
            <li>
              <span class="icon-wg wg-phone"></span
              ><a :href="`tel:${links.phone.replace(/\s/g, '')}`">{{
                links.phone
              }}</a>
            </li>
            <li>
              <span class="icon-wg wg-mail-outline"></span
              ><a :href="`mailto:${links.email}`">{{ links.email }}</a>
            </li>
          </ul>

          <ul class="social-links-list">
            <li class="social-link-item">
              <a :href="links.facebook" aria-label="facebook" target="_blank"
                ><span class="wg-facebook"></span
              ></a>
            </li>
            <li class="social-link-item">
              <a :href="links.instagram" aria-label="instagram" target="_blank"
                ><span class="wg-instagram"></span
              ></a>
            </li>
            <li class="social-link-item">
              <a :href="links.telegram" aria-label="telegram" target="_blank"
                ><span class="wg-telegram"></span
              ></a>
            </li>
            <li class="social-link-item">
              <a
                :href="`mailto:${links.email}`"
                aria-label="email"
                target="_blank"
                ><span class="wg-mail"></span
              ></a>
            </li>
            <li class="social-link-item">
              <a :href="links.linkedin" aria-label="linkedin" target="_blank"
                ><span class="wg-linkedin"></span
              ></a>
            </li>
          </ul>

          <div class="cliple-tech-wrap">
            <div class="cliple-tech-background"></div>
              <div class="cliple-tech-link">
                <a href="https://cliple.tech/">
                <div>
                  <span><b>C</b>liple Tech</span>
                  <span>Перейти</span>
                </div>
                <div>
                  <span>&mdash;</span>
                  <span>наше подразделение для работы с клиентами из англоязычных стран</span>
                </div>
                </a>
              </div>
          </div>

          <div class="copyright">
            Copyright © 2020 WGroup
          </div>
        </div>
        <div class="footer-col footer-form">
          <div class="form-title">Свяжитесь с нами</div>
          <div class="form-desc">Мы ответим вам в ближайшее время</div>
          <form @submit.prevent="submitContact" id="contact-form">
            <div class="form-control">
              <label>
                <span>Ваше имя*</span>
                <input
                  type="text"
                  name="name"
                  required
                  v-model="contactForm.name"
                />
              </label>
            </div>
            <div class="form-control">
              <label>
                <span>Email*</span>
                <input
                  type="email"
                  name="email"
                  required
                  v-model="contactForm.email"
                />
              </label>
            </div>
            <div class="form-control">
              <label>
                <span>Ваш номер телефона</span>
                <input
                  type="tel"
                  name="phone"
                  required
                  @keypress="validate"
                  @paste="validate"
                  v-model="contactForm.phone"
                />
              </label>
            </div>
            <div class="form-control">
              <label>
                <span>Сообщение</span>
                <textarea
                  name="message"
                  required
                  v-model="contactForm.message"
                ></textarea>
              </label>
            </div>
            <button class="btn btn-white" type="submit">Отправить</button>
          </form>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import links from "@/data/links.json";
export default {
  name: "AppFooter",
  data() {
    return {
      links,
      contactForm: {
        name: "",
        email: "",
        phone: "",
        message: ""
      }
    };
  },
  methods: {
    validate(evt) {
      let theEvent = evt;
      let key;

      // Handle paste
      if (theEvent.type === "paste") {
        key = evt.clipboardData.getData("text/plain");
      } else {
        // Handle key press
        key = theEvent.keyCode;
        key = String.fromCharCode(key);
      }
      let regex = /^[0-9+]*$/;
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    },

    async submitContact() {
      let data = new FormData();

      data.append("name", this.contactForm.name);
      data.append("email", this.contactForm.email);
      data.append("phone", this.contactForm.phone);
      data.append("message", this.contactForm.message);

      try {
        const response = await fetch("/api/index.php", {
          method: "POST",
          body: data
        });
        const result = await response.json();

        if (result.success) {
          this.emitter.emit("openModal", { type: "DialogSuccess" });
          this.contactForm = {
            name: "",
            email: "",
            phone: "",
            message: ""
          };
        } else {
          this.emitter.emit("openModal", {
            type: "DialogFailed",
            retry: this.submitContact
          });
        }
      } catch (e) {
        this.emitter.emit("openModal", {
          type: "DialogFailed",
          retry: this.submitContact
        });
      }
    }
  }
};
</script>

<style scoped></style>
