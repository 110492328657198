<template>
  <div
    class="d-flex align-items-center justify-content-center "
    style="padding: 100px 0 50px"
  >
    <div class="text-center">
      <div class="header-title justify-content-center">404</div>
      <div class="header-subtitle">Страница не найдена</div>
      <router-link to="/" replace class="btn btn-large btn-gradient btn-connect"
        >На главную</router-link
      >
    </div>
  </div></template
>

<script>
export default {
  name: "404"
};
</script>

<style scoped></style>
