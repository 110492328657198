<template>
  <AppHeader />
  <router-view v-slot="{ Component }">
    <transition name="fade" appear>
      <component :is="Component" />
    </transition>
  </router-view>
  <AppFooter />
  <component
    :is="dialogParams.type"
    v-model="openDialog"
    :retry="dialogParams.retry"
  ></component>
</template>

<script>
import DialogSuccess from "@/components/DialogSuccess";
import DialogFailed from "@/components/DialogFailed";

import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";

export default {
  components: { AppFooter, AppHeader, DialogFailed, DialogSuccess },
  data() {
    return {
      openDialog: false,
      dialogParams: {
        type: "DialogSuccess",
        retry: () => {}
      }
    };
  },
  created() {
    this.emitter.on("openModal", ({ type, retry }) => {
      this.dialogParams.type = type;
      if (retry) {
        this.dialogParams.retry = retry;
      }
      this.openDialog = true;
    });
  }
};
</script>
