<template>
  <section
    class="header-section"
    :class="{
      'header-vacancy': isVacancyPage || $route.name === 'NotFound'
    }"
  >
    <div class="header-menu" :class="{ 'is-active': isOpenMenu }">
      <button
        class="btn menu-btn hamburger hamburger--collapse"
        :class="{ 'is-active': isOpenMenu, 'dark-btn': scrollTop > 100 }"
        ref="menuBtn"
        @click="toggleMenu"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
        <span class="menu-text">
          <span class="menu-text--open">MENU</span>
          <span class="menu-text--close">CLOSE</span>
        </span>
      </button>
      <nav>
        <ul>
          <li>
            <router-link to="/" custom v-slot="{ href, navigate }">
              <a
                :href="href"
                @click="navigate"
                :class="[href === $route.fullPath && 'active']"
              >
                Главная
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/#about" custom v-slot="{ href, navigate }">
              <a
                :href="href"
                @click="navigate"
                :class="[href === $route.fullPath && 'active']"
              >
                О компании
              </a>
            </router-link>
          </li>
          <li>
            <router-link
              active-class="active"
              to="/vacancies"
              @click.passive="isOpenMenu = false"
              >Вакансии
            </router-link>
          </li>
          <li>
            <router-link to="/#contacts" custom v-slot="{ href, navigate }">
              <a
                :href="href"
                @click="navigate"
                :class="[href === $route.fullPath && 'active']"
              >
                Контакты
              </a>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <div class="social-links">
      <ul class="social-links-list">
        <li class="social-link-item d-md-none">
          <a :href="links.facebook" aria-label="facebook" target="_blank"
            ><span class="wg-facebook"></span
          ></a>
        </li>
        <li class="social-link-item">
          <a :href="links.instagram" aria-label="instagram" target="_blank"
            ><span class="wg-instagram"></span
          ></a>
        </li>
        <li class="social-link-item">
          <a :href="links.telegram" aria-label="telegram" target="_blank"
            ><span class="wg-telegram"></span
          ></a>
        </li>
        <li class="social-link-item d-md-none">
          <a :href="`mailto:${links.email}`" aria-label="email" target="_blank"
            ><span class="wg-mail"></span
          ></a>
        </li>
        <li class="social-link-item">
          <a :href="links.linkedin" aria-label="linkedin" target="_blank"
            ><span class="wg-linkedin"></span
          ></a>
        </li>
      </ul>
    </div>
    <div class="header-content">
      <template v-if="isVacancyPage || $route.name === 'NotFound'">
        <div
          class="header-vacancy-logo"
          v-if="$route.params.slug"
          :style="{
            background: `url(/images/dist/${$route.params.slug}.svg) no-repeat`
          }"
        ></div>
        <h1
          class="header-title"
          :class="{ 'vacancies-title': !$route.params.slug }"
        >
          <template v-if="$route.params.slug">
            {{ vacancyTitle.level }} <br />
            {{ vacancyTitle.name }}
          </template>
          <template v-else>
            <span class="ff-babas-c">{{
              $route.name === "NotFound" ? 404 : "Вакансии"
            }}</span>
          </template>
        </h1>
        <div
          class="header-text"
          v-if="!$route.params.slug && $route.name !== 'NotFound'"
        >
          Стань неотъемлемой частью <br />
          талантливой команды WGroup
        </div>
        <h2
          class="header-subtitle"
          v-if="!$route.params.slug && $route.name === 'NotFound'"
        >
          Страница не найдена
        </h2>
      </template>
      <template v-else>
        <h1 class="header-title">
          WGroup
        </h1>
        <h2 class="header-subtitle">
          Твои способности <br />
          достойны большего
        </h2>
        <div class="header-text">
          Стань неотъемлемой частью <br />
          талантливой команды WGroup
        </div>
        <a
          :href="`mailto:${links.email}`"
          class="btn btn-large btn-gradient btn-connect"
        >
          Присоединяйся <span class="wg-arrow"></span>
        </a>
      </template>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <div class="scroll">
        <span class="scroll-line"></span>
        <span>Скроль</span>
      </div>
    </div>
  </section>
</template>

<script>
import links from "@/data/links.json";
export default {
  name: "AppHeader",
  data() {
    return {
      links,
      isOpenMenu: false,
      vacancyTitle: {},
      scrollTop: 0
    };
  },
  computed: {
    isVacancyPage() {
      return this.$route.path.includes("vacancies");
    }
  },
  methods: {
    toggleMenu() {
      this.isOpenMenu = !this.isOpenMenu;
    }
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.isOpenMenu = false;
      },
      { immediate: true }
    );
    this.emitter.on("setVacancyTitle", vacancy => {
      this.vacancyTitle = vacancy;
    });
  },
  mounted() {
    document.addEventListener("scroll", e => {
      this.scrollTop = e.target.documentElement.scrollTop;
    });
    document.addEventListener("click", e => {
      if (!e.target.closest(".header-menu")) {
        this.isOpenMenu = false;
      }
    });
  }
};
</script>

<style scoped></style>
