<template>
  <transition name="fade">
    <div class="dialog fail-dialog " v-if="modelValue" @click.self="closeModal">
      <div class="dialog-body dialog-fail">
        <button class="btn-close" @click="closeModal">
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.323938 11.5757L11.5742 0.325439L12.3242 1.07546L1.07396 12.3257L0.323938 11.5757Z"
              fill="black"
            />
            <path
              d="M0.854023 0.438511L12.1043 11.6888L11.3543 12.4388L0.104004 1.18853L0.854023 0.438511Z"
              fill="black"
            />
          </svg>
        </button>
        <div class="dialog-content">
          <div class="title">
            Извините!
          </div>
          <div class="text">
            Что-то пошло не так. <br />
            Ваше сообщение не отправлено
          </div>
          <button class="btn btn-red" @click="retryRequest">Повторить</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "DialogFailed",
  props: {
    modelValue: Boolean,
    retry: {
      type: Function,
      default: () => {}
    }
  },
  emits: ["update:modelValue"],
  methods: {
    closeModal() {
      this.$emit("update:modelValue", false);
    },
    retryRequest() {
      this.retry();
      this.closeModal();
    }
  }
};
</script>

<style scoped></style>
